.CalendarDay__selected_span {
    background: #e14eca;
    color: white;
    border: 1px solid;
}


.CalendarDay__selected {
    background: #27293d;
    color: white;
}


.CalendarDay__selected:hover {
    background: white;
    color: black;
}


.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #e14eca;
}

.DateRangePicker {
    position: relative;
    display: inline-block;
    border-radius: 0.4285rem;
    background-color: #27293d;

}

.DateRangePickerInput {
    display: inline-block;
    border-radius: 0.4285rem;
    background-color: #27293d;
}

.DateInput {
    margin: 0;
    padding: 0;
    background-color: #27293d;
    position: relative;
    display: inline-block;
    width: 130px;
    vertical-align: middle;
    color: #ffffff;
}

.DateInput_input {
    font-weight: 200;
    font-size: 19px;
    line-height: 24px;
    box-shadow: none;
    width: 100%;
    padding: 11px 11px 9px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0.4285rem;
    background: #e14eca;
    background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca);
    background-size: 210% 210%;
    background-position: top right;
    background-color: #e14eca;
    transition: all 0.15s ease;
    box-shadow: none;
    color: #ffffff;
    font-size: 0.875rem;
    text-decoration-color: #ffffff;
    height: 42px;


}

.DateRangePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #27293d;
    color: #ffffff;
    margin-left: 10px;
}

.DateRangePicker_picker {
    z-index: 1;
    position: absolute;

}

.DayPicker {
    position: relative;
    text-align: left;
}

DateRangePicker_picker__fullScreenPortal {
    background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
}

.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px;

}

.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #ffffff
}

.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #ffffff;
    height: 24px;
    width: 24px
}