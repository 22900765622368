
@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?pc83uy');
    src:  url('../fonts/icomoon.eot?pc83uy#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?pc83uy') format('truetype'),
    url('../fonts/icomoon.woff?pc83uy') format('woff'),
    url('../fonts/icomoon.svg?pc83uy#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="iconmoon-"], [class*=" iconmoon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.iconmoon-help:before {
    content: "\e933";
}
.iconmoon-walkie-talkie:before {
    content: "\e932";
}
.iconmoon-lotti:before {
    content: "\e907";
}
.iconmoon-uorg:before {
    content: "\e908";
}
.iconmoon-website-with-different-sections:before {
    content: "\e927";
}
.iconmoon-delivery-packages-on-a-trolley:before {
    content: "\e928";
}
.iconmoon-data-table:before {
    content: "\e929";
}
.iconmoon-live-1:before {
    content: "\e92a";
}
.iconmoon-settings-gears:before {
    content: "\e92b";
}
.iconmoon-database:before {
    content: "\e92c";
}
.iconmoon-live:before {
    content: "\e92d";
}
.iconmoon-forklift-with-boxes:before {
    content: "\e92e";
}
.iconmoon-international-delivery:before {
    content: "\e92f";
}
.iconmoon-kpi:before {
    content: "\e930";
}
.iconmoon-location-on-map:before {
    content: "\e931";
}
.iconmoon-square:before {
    content: "\e926";
}
.iconmoon-bricks:before {
    content: "\e925";
}
.iconmoon-travelling-vehicles-of-a-road:before {
    content: "\e922";
}
.iconmoon-icon:before {
    content: "\e923";
}
.iconmoon-cargo-truck:before {
    content: "\e924";
}
.iconmoon-trash:before {
    content: "\e91e";
}
.iconmoon-tools:before {
    content: "\e91f";
}
.iconmoon-car-collision:before {
    content: "\e920";
}
.iconmoon-writing:before {
    content: "\e921";
}
.iconmoon-customer:before {
    content: "\e91d";
}
.iconmoon-timeline-5:before {
    content: "\e919";
}
.iconmoon-video-editing:before {
    content: "\e91a";
}
.iconmoon-timeline-4:before {
    content: "\e91b";
}
.iconmoon-timeline-3:before {
    content: "\e91c";
}
.iconmoon-timeline-2:before {
    content: "\e916";
}
.iconmoon-timeline-1:before {
    content: "\e917";
}
.iconmoon-timeline:before {
    content: "\e918";
}
.iconmoon-profit:before {
    content: "\e90b";
}
.iconmoon-calendar:before {
    content: "\e90c";
}
.iconmoon-man:before {
    content: "\e90d";
}
.iconmoon-mining-work-zone:before {
    content: "\e90e";
}
.iconmoon-dashboard:before {
    content: "\e90f";
}
.iconmoon-menu-2:before {
    content: "\e910";
}
.iconmoon-invoice:before {
    content: "\e911";
}
.iconmoon-signing-the-contract:before {
    content: "\e912";
}
.iconmoon-visitor-identification:before {
    content: "\e913";
}
.iconmoon-clerk-with-tie:before {
    content: "\e914";
}
.iconmoon-management:before {
    content: "\e915";
}
.iconmoon-logout:before {
    content: "\e909";
}
.iconmoon-home:before {
    content: "\e90a";
}
.iconmoon-order:before {
    content: "\e900";
}
.iconmoon-arrow-down-sign-to-navigate:before {
    content: "\e903";
}

.iconmoon-up-arrow:before {
    content: "\e904";
}

.iconmoon-data:before {
    content: "\e905";
}

.iconmoon-human-resources-data-of-job-performance:before {
    content: "\e901";
}

.iconmoon-seo-performance-marketing-graphic:before {
    content: "\e902";
}

.iconmoon-menu:before {
    content: "\e906";
    color: #fff;
}
